import { createTheme, Theme } from "@mui/material";
// Others
import { customConfig, defaultConfig } from "./commonConfig";
import { CustomThemeProps } from "../models";

const defaultTheme = createTheme(defaultConfig);

export const DarkTheme: CustomThemeProps = {
  ...customConfig,
  ...defaultTheme,
};
