import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveNFTsAPI,
  cancelNFTsAPI,
  getNFTsAPI,
} from "../api/launchpadAPIs";
import DetailsTable from "../components/Tables/DetailsTable";
import { LAUNCHPAD_STATUS } from "../const";
import { NFTLaunchpad } from "../models/blockchain";

export const NFTDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [editStatus, setEditStatus] = useState(false);
  const [select, setSelect] = useState("");

  const [nft, setNFT] = useState<NFTLaunchpad>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getNFT() {
      const res = await getNFTsAPI();
      setNFT(res?.find((item: NFTLaunchpad) => item?.nftStorageId === id));
    }

    getNFT();
  }, [id]);

  const handleChangeStatus = async () => {
    setLoading(true);
    try {
      if (select === "cancel") {
        await cancelNFTsAPI(id as string);
      }

      if (select === "approve") {
        await approveNFTsAPI(id as string);
      }

      const res = await getNFTsAPI();
      const tmpNft = res?.find(
        (item: NFTLaunchpad) => item?.nftStorageId === id
      );
      setNFT(tmpNft);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    setEditStatus(false);
  };

  console.log(nft);

  return (
    <div>
      <div className="py-10">
        <div className="flex justify-between text-white">
          <span className="font-montserrat500 text-xl text-fifth">
            NFT Information {">>"}
          </span>
          <div className="flex">
            <button
              className="flex items-center mr-10"
              onClick={() => navigate(-1)}
            >
              <span className="text-seventh rounded-[50%] w-6 h-6 bg-white flex justify-center items-center mr-4">
                <ArrowBackIcon className="text-sm " />
              </span>
              <span className="underline text-lg font-raleway700">Back</span>
            </button>
            {/* <span>
            <MoreVertIcon />
          </span> */}
          </div>
        </div>
        <span className="text-[26px] font-montserrat800 text-white">
          {nft?.nftStorageId}
        </span>
      </div>
      <div className="px-[10px] lg:px-[100px] xl:px-[200px]">
        <div className="bg-ninth px-[25px] lg:px-[95px] py-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 rounded-md justify-between">
          <div className="mr-10 lg:max-w-[150px]">
            <p className="font-raleway500 text-base pb-2.5 text-fifth">
              Amount
            </p>
            <span className="text-[17px] text-white font-raleway700 break-words ">
              {nft?.amount} TAL
            </span>
          </div>

          <div className="mr-10 lg:max-w-[150px]">
            <p className="font-raleway500 text-base pb-2.5 text-fifth">Owner</p>
            <span className="text-[17px] text-white font-raleway700 break-words ">
              {nft?.ownerId}
            </span>
          </div>
          <div className="pt-[30px] lg:pt-[0]">
            <p className="font-raleway500  text-base pb-2.5 text-fifth">
              Transaction Status{" "}
            </p>
            {!editStatus ? (
              <span className={`text-[17px] font-raleway700 text-fourth `}>
                {nft?.status} <br />
                {nft?.status === LAUNCHPAD_STATUS.Pending && (
                  <small
                    onClick={() => setEditStatus(true)}
                    className="text-fourth font-semibold cursor-pointer text-[12px]"
                  >
                    Update status
                  </small>
                )}
              </span>
            ) : (
              <span className={`text-[17px] font-raleway700 text-fourth `}>
                <select
                  onChange={(e) => setSelect(e.target.value as string)}
                  id="location"
                  name="location"
                  className="mt-1 block w-full rounded-md bg-seventh py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  defaultValue="pending"
                >
                  <option value="pending">Pending</option>
                  <option value="approve">Approve</option>
                  <option value="cancel">Reject</option>
                </select>
                <br />
                <small
                  onClick={handleChangeStatus}
                  className="text-fourth font-semibold cursor-pointer text-[12px]"
                >
                  Save
                </small>
              </span>
            )}
          </div>
        </div>
        <div className="pt-4">
          <DetailsTable
            img={nft?.launchpadLink}
            props={{
              title: "Player Infomation",
              content: [
                {
                  title: "Player name",
                  content: `${nft?.metadata?.properties?.player?.name}`,
                },
                {
                  title: "Age",
                  content: `${nft?.metadata?.properties?.player?.age}`,
                },
                {
                  title: "Club",
                  content: `${nft?.metadata?.properties?.player?.club}`,
                },
                {
                  title: "Position",
                  content: `${nft?.metadata?.properties?.player?.position}`,
                },
                {
                  title: "Nationality",
                  content: `${nft?.metadata?.properties?.player?.nationality}`,
                },
                {
                  title: "Description",
                  content: `${nft?.metadata?.properties?.player?.about}`,
                },
                {
                  title: "Special",
                  content: `${nft?.metadata?.name}`,
                },
              ],
            }}
          />
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!nft || loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
