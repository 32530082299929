export const parseEmpty = (text: string) => {
  if (Boolean(text?.trim)) {
    return text;
  }
  return "";
};
export const importantStyle = (text: string) => {
  return text + "!important";
};
export const accountingFormat = (number: number) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

export const toProxyURL = (url: string) => {
  return (
    process.env.REACT_APP_API_LINK + `/proxy/?url=${encodeURIComponent(url)}`
  );
};
