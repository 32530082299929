import { GridColDef } from "@mui/x-data-grid";

//import component
import MoreButton from "../../components/Tables/MoreButton";

// import custom hooks

// Const
import { LAUNCHPAD_STATUS } from "../../const";

// import icon libarary
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { NavigateFunction } from "react-router-dom";

const checkStatus = (status: string) => {
  switch (status) {
    case LAUNCHPAD_STATUS.Pending:
      return "text-twentyOne";
    case LAUNCHPAD_STATUS.Approved:
      return "text-twentyThree";
    case LAUNCHPAD_STATUS.Cancelled:
      return "text-red-700";
  }
  return "";
};

export const columns = (
  handleCancel: any,
  handleApproved: any
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 20,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.id}</span>
        </div>
      ),
    },
    {
      field: "nftStorageId",
      headerName: "Nft StorageId",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.nftStorageId}</span>
        </div>
      ),
    },
    {
      field: "time",
      headerName: "Time",
      sortable: false,
      minWidth: 300,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {rowData.row?.time
              ? moment(rowData.row?.time).format("DD/MM/YYYY h:mm:ss a")
              : ""}
          </span>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.name}</span>
        </div>
      ),
    },

    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      minWidth: 20,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.amount}</span>
        </div>
      ),
    },

    {
      field: "owner",
      headerName: "Owner",
      sortable: false,
      minWidth: 400,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.owner}</span>
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => {
        return (
          <div
            className={`flex justify-between w-full  ${checkStatus(
              rowData.row.status
            )}`}
          >
            <span className="max-w-[90%] scrollTableX ">
              {rowData.row.status}
            </span>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      minWidth: 50,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => {
        return (
          <div>
            <MoreButton
              props={{
                buttons: [
                  {
                    icon: <RemoveRedEyeIcon />,
                    quote: "View",
                    popup: false,
                    handleClick: (navigate: NavigateFunction) => {
                      navigate(`/nfts/${rowData.row.nftStorageId}`);
                    },
                  },
                  {
                    ...(rowData.row.status === LAUNCHPAD_STATUS?.Pending &&
                      ({
                        icon: <CheckCircleOutlineIcon />,
                        quote: "Approve",
                        popup: false,
                        handleClick: () => {
                          handleApproved(rowData.row.nftStorageId);
                        },
                      } as any)),
                  },
                  {
                    ...(rowData.row.status === LAUNCHPAD_STATUS?.Pending &&
                      ({
                        icon: <Close />,
                        quote: "Reject",
                        popup: false,
                        handleClick: () =>
                          handleCancel(rowData.row.nftStorageId),
                      } as any)),
                  },
                ],
              }}
            />
          </div>
        );
      },
    },
  ];
};
export const columnsTransactionNft = (
  handleCancel: any,
  handleApproved: any
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "Id",
      sortable: false,
      minWidth: 50,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.id}</span>
        </div>
      ),
    },
    {
      field: "transaction_hash",
      headerName: "Transaction hash",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.transaction_hash}</span>
        </div>
      ),
    },

    {
      field: "amount",
      headerName: "NFT Amount",
      sortable: false,
      minWidth: 100,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.amount}</span>
        </div>
      ),
    },
    {
      field: "nftid",
      headerName: "NFT ID",
      sortable: false,
      minWidth: 100,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.tokenID}</span>
        </div>
      ),
    },
    // {
    //   field: "receipt_gas_used",
    //   headerName: "Receipt Gas Used",
    //   sortable: false,
    //   minWidth: 200,
    //   flex: 2,
    //   headerClassName: "header ",
    //   cellClassName: "cell ",
    //   disableColumnMenu: true,
    //   renderCell: (rowData: any) => (
    //     <div className="scrollTableX">
    //       <span>{rowData.row.receipt_gas_used ? Number(rowData.row.receipt_gas_used)/1e+6 :''}</span>
    //     </div>
    //   ),
    // },
    {
      field: "talamount",
      headerName: "TAL Amount",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell ",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {rowData.row.sellerTALAmount
              ? (rowData.row.sellerTALAmount + rowData.row.feeTALAmount) / 10e17
              : 0}{" "}
            TAL
          </span>
        </div>
      ),
    },
    {
      field: "sellertalamount",
      headerName: "Seller's TAL amount",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell ",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {rowData.row.sellerTALAmount
              ? rowData.row.sellerTALAmount / 10e17
              : 0}{" "}
            TAL
          </span>
        </div>
      ),
    },
    {
      field: "feetalamount",
      headerName: "Fee TAL (Revenue) amount",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell ",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {rowData.row.feeTALAmount ? rowData.row.feeTALAmount / 10e17 : 0}{" "}
            TAL
          </span>
        </div>
      ),
    },
    {
      field: "feetalpercent",
      headerName: "Fee TAL (Revenue) percent",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell ",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {rowData.row.feePercent
              ? rowData.row.feePercent || rowData.row.feePercent2Hand
              : 0}{" "}
            %
          </span>
        </div>
      ),
    },
    // {
    //   field: "revenue_fee",
    //   headerName: "Revenue",
    //   sortable: false,
    //   minWidth: 100,
    //   flex: 2,
    //   headerClassName: "header",
    //   cellClassName: "cell",
    //   disableColumnMenu: true,
    //   renderCell: (rowData: any) => (
    //     <div className="scrollTableX">
    //       <span>{rowData.row.revenue_fee / 10e17}</span>
    //     </div>
    //   ),
    // },
    {
      field: "block_timestamp",
      headerName: "Date time",
      sortable: false,
      minWidth: 250,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {" "}
            {moment(rowData.row.block_timestamp * 10e2).format(
              "MMM Do YY, h:mm:ss a"
            )}
          </span>
        </div>
      ),
    },

    {
      field: "from_address",
      headerName: "From Address",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.from_address}</span>
        </div>
      ),
    },
    {
      field: "to_address",
      headerName: "To Address",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.to_address}</span>
        </div>
      ),
    },
    {
      field: "block_number",
      headerName: "Block Number",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.block_number}</span>
        </div>
      ),
    },
    {
      field: "transaction_fee",
      headerName: "Transaction Fee",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell ",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>
            {rowData.row.receipt_gas_used ? (
              <div>
                {(rowData.row.receipt_gas_used * rowData.row.gas_price) / 1e18}{" "}
                BNB
              </div>
            ) : (
              ""
            )}{" "}
          </span>
        </div>
      ),
    },
    // {
    //   field: "transaction_index",
    //   headerName: "Transaction Index",
    //   sortable: false,
    //   minWidth: 100,
    //   flex: 2,
    //   headerClassName: "header",
    //   cellClassName: "cell",
    //   disableColumnMenu: true,
    //   renderCell: (rowData: any) => (
    //     <div className="scrollTableX">
    //       <span>{rowData.row.transaction_index}</span>
    //     </div>
    //   ),
    // },
  ];
};
