import { FlipNFTCollectionDoc, FlipNFTDoc } from "../models/blockchain";
import { axiosGroup } from "./axiosGroup";

export const getAllCollections = async () => {
  const result = await axiosGroup.axiosServerAPI.get("/admin/collection");

  return result.data;
};
export const updateCollectionById = async (payload: FlipNFTCollectionDoc) => {
  const result = await axiosGroup.axiosServerAPI.put(
    `/admin/collection/${payload.collectionId}`,
    payload
  );

  return result.data;
};

export const getCollectionById = async (collectionId: string) => {
  const result = await axiosGroup.axiosServerAPI.get(
    `/admin/collection/${collectionId}`
  );
  return result.data.data;
};

export const updateNFTById = async (payload: FlipNFTDoc) => {
  const result = await axiosGroup.axiosServerAPI.put(
    `/admin/nft/${payload.nftId}`,
    payload
  );

  return result.data;
};

export const cancelCollectionById = async (collectionId: string) => {
  const result = await axiosGroup.axiosServerAPI.put(
    `/admin/collection/cancel/${collectionId}`
  );

  return result.data;
};

export const approveCollections = async (payload: { ids: string[] }) => {
  const result = await axiosGroup.axiosServerAPI.put(
    `/admin/collection/approve`,
    payload
  );

  return result.data;
};
