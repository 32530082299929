import { ThemeOptions } from "@mui/material";

export const customConfig = {
  fontFamilies: {
    montserrat400: "Montserrat400",
    montserrat800: "Montserrat800",
    montserrat700: "Montserrat700",
    montserrat600: "Montserrat600",
    montserrat500: "Montserrat500",
    montserrat300: "Montserrat300",
    raleway400: "Raleway400",
    raleway800: "Raleway800",
    raleway700: "Raleway700",
    raleway600: "Raleway600",
    raleway500: "Raleway500",
    raleway300: "Raleway300",
  },
  colors: {
    first: "#60EBEB",
    second: "#6BA8F6",
    third: "#6F87F9",
    fourth: "#64D3EF",
    fifth: "#000",
    sixth: "#071734",
    seventh: "#040826",
    eighth: "#171832",
    ninth: "#32334F",
    tenth: "#EBEBF5",
  },
  customTextColor: {
    note: "#C7C6C8",
    specialNote: "#0AB4E4",
    grey: "#CACFED",
    first: "#64D3EF",
    second: "#FF4D00",
  },
  input: {
    borderColor: "#C7C6C8",
  },
  borderColors: {
    first: "#2F304B",
    second: "#64D3EF",
    third: "#E53BE6",
    fourth: "#434460",
  },
  paragraph: {
    p1: {
      fontFamily: "Raleway500",
      fontSize: 27,
    },
    p2: {
      fontFamily: "Raleway500",
      fontSize: 20,
    },
    p3: {
      fontFamily: "Raleway500",
      fontSize: 18,
    },
    p4: {
      fontFamily: "Raleway500",
      fontSize: 14,
    },
  },
  customButton: {
    background: "linear-gradient(270deg, #A051F3 0%, #E53BE6 100%)",
    borderRadius: "4px",
  },
};

export const defaultConfig: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: "Montserrat800",
      fontSize: 36,
    },
    h2: {
      fontFamily: "Montserrat700",
      fontSize: 24,
    },
    h3: {
      fontFamily: "Raleway700",
      fontSize: 18,
    },
  },
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
};
