import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { Spinner } from "../components";
import { slugs } from "../const/slugs";
import { Launchpad } from "../pages/LaunchpadList";
import { LaundpadDetail } from "../pages/LaundpadDetail";
import { NFTDetail } from "../pages/NFTDetail";
import { NFTs } from "../pages/NFTs";

import { RewardNFT } from "../pages/RewardNFT";
import { TransactionNft } from "../pages/TransactionNft";
// Pages
const Dashboard = lazy(() => import("../pages/Dashboard"));
const TransactionDetails = lazy(() => import("../pages/TransactionDetails"));
const Transaction = lazy(() => import("../pages/Transaction"));
const UserDetails = lazy(() => import("../pages/UserDetails"));
const UserList = lazy(() => import("../pages/UserList"));
const Locked = lazy(() => import("../pages/Locked"));
const NFTViews = lazy(() => import("../pages/NftViews"));
const FlipNFTs = lazy(() => import("../pages/FlipNFTs"));
const FlipNFTDetail = lazy(() => import("../pages/FlipNFTDetail"));

export const routes = [
  {
    path: slugs.dashboard,
    element: (
      <Suspense fallback={<Spinner />}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: slugs.transactions,
    element: (
      <Suspense fallback={<Spinner />}>
        <Transaction />
      </Suspense>
    ),
  },
  {
    path: slugs.users,
    element: (
      <Suspense fallback={<Spinner />}>
        <UserList />
      </Suspense>
    ),
  },
  {
    path: slugs.locked,
    element: (
      <Suspense fallback={"Loading..."}>
        <Locked />
      </Suspense>
    ),
  },
  {
    path: slugs.transaction,
    element: (
      <Suspense fallback={<Spinner />}>
        <TransactionDetails />
      </Suspense>
    ),
  },
  {
    path: slugs.user,
    element: (
      <Suspense fallback={<Spinner />}>
        <UserDetails />
      </Suspense>
    ),
  },
  {
    path: slugs.laundpads,
    element: (
      <Suspense fallback={<Spinner />}>
        <Launchpad />
      </Suspense>
    ),
  },
  {
    path: slugs.laundpad,
    element: (
      <Suspense fallback={<Spinner />}>
        <LaundpadDetail />
      </Suspense>
    ),
  },
  {
    path: slugs.nfts,
    element: (
      <Suspense fallback={<Spinner />}>
        <NFTs />
      </Suspense>
    ),
  },
  {
    path: slugs.nftViews,
    element: (
      <Suspense fallback={<Spinner />}>
        <NFTViews />
      </Suspense>
    ),
  },
  {
    path: slugs.transactionnft,
    element: (
      <Suspense fallback={<Spinner />}>
        <TransactionNft />
      </Suspense>
    ),
  },
  {
    path: slugs.nft,
    element: (
      <Suspense fallback={<Spinner />}>
        <NFTDetail />
      </Suspense>
    ),
  },
  {
    path: slugs.reward,
    element: (
      <Suspense fallback={<Spinner />}>
        <RewardNFT />
      </Suspense>
    ),
  },
  {
    path: slugs.flipNFTs,
    element: (
      <Suspense fallback={<Spinner />}>
        <FlipNFTs />
      </Suspense>
    ),
  },
  {
    path: slugs.flipNFTsDetail,
    element: (
      <Suspense fallback={<Spinner />}>
        <FlipNFTDetail />
      </Suspense>
    ),
  },
  {
    path: slugs.notfound,
    element: (
      <Suspense fallback={<Spinner />}>
        <h1>Not Found</h1>
      </Suspense>
    ),
  },
  {
    path: "*",
    element: <Navigate to={slugs.notfound} />,
  },
];
