import { createTheme, Theme } from "@mui/material";

// Others
import { customConfig, defaultConfig } from "./commonConfig";

const defaultTheme = createTheme(defaultConfig);

export const LightTheme: Theme = {
  ...customConfig,
  ...defaultTheme,
};
