import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Store
import { getTransactionLoading } from "../../store";
// Styled Components
import { StyledLoadingButton } from "../Buttons";
//import custom hook
import { TOAST_STATE } from "../../const";
import { ClickOutSide } from "../../hooks/customHook";
import { triggerToast } from "../../utils";

type PrivateProps = {
  props: any;
};

type Button = {
  popup: boolean;
  quote: string;
  modal: {
    title: string;
    checkWallet: true;
    handleYes: (account?: string | null) => void;
  };
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function MoreButton({ props }: PrivateProps) {
  const { account } = useWeb3React();

  // useNavigate
  const navigate = useNavigate();

  // useSelector
  const loading = useSelector(getTransactionLoading);

  // useState
  const [button, setButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState<Button | null>(null);

  // Other functions
  const handleOpen = (button: Button) => {
    setSelectedButton(button);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickOutSide
      callBack={() => {
        if (!open) setButton(false);
      }}
    >
      <>
        <span className="relative z-50">
          <MoreVertIcon
            className="hover:opacity-50 hover:cursor-pointer"
            onClick={() => {
              setButton(!button);
            }}
          />

          {button && (
            <div
              style={{
                background: "#eee",
              }}
              className="absolute top-0 right-[100%] shadow flex flex-col cursor-pointer"
            >
              {props.buttons.map((element: any, index: number) => {
                return (
                  <span
                    className={`py-3 px-3 hover:opacity-50 ${
                      element?.quote === "Approve"
                        ? "text-twentyThree !important"
                        : element?.quote === "Reject"
                        ? "text-red-700 !important"
                        : ""
                    }`}
                    key={index}
                  >
                    <span
                      className={"mr-4"}
                      onClick={
                        element.popup
                          ? () => handleOpen(element)
                          : () => element.handleClick(navigate)
                      }
                    >
                      {element.icon}
                    </span>
                    <span
                      className={`font-raleway600 text-base pr-10`}
                      onClick={
                        element.popup
                          ? () => handleOpen(element)
                          : () => element.handleClick(navigate)
                      }
                    >
                      {element.quote}
                    </span>
                  </span>
                );
              })}
            </div>
          )}
        </span>
        {selectedButton && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              className="text-white bg-twentyFive px-8 py-9 rounded-xl"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div
                  className="flex justify-end cursor-pointer hover:opacity-50"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
                <div className="px-16 grid grid-cols-2 gap-14 ">
                  <span className="col-span-2 font-raleway700 text-[22px]">
                    {selectedButton.modal.title}
                  </span>
                  <button
                    className="rounded-lg font-raleway700 text-xl py-4 px-20 bg-twentySix hover:opacity-50"
                    onClick={handleClose}
                  >
                    NO
                  </button>
                  <StyledLoadingButton
                    loading={loading}
                    className="!rounded-lg !font-raleway700 !text-xl !py-4 !px-20 bg-gradient-to-r from-first to-third hover:opacity-50"
                    onClick={async () => {
                      if (!account && selectedButton.modal.checkWallet)
                        return triggerToast(
                          "Please connect wallet firstly",
                          TOAST_STATE.Info
                        );

                      await selectedButton.modal.handleYes(account);

                      handleClose();
                    }}
                  >
                    Yes
                  </StyledLoadingButton>
                </div>
              </Typography>
            </Box>
          </Modal>
        )}
      </>
    </ClickOutSide>
  );
}

export default MoreButton;
