export const slugs = {
  dashboard: "/",

  users: "/users",
  user: "/users/:id",

  laundpads: "/laundpads",
  laundpad: "/laundpads/:id",

  nfts: "/nfts",
  flipNFTs: "/flip-nfts",
  flipNFTsDetail: "/flip-nfts/:collectionId",
  nftViews: "/nft-views",
  nft: "/nfts/:id",
  transactionnft: "/transactionnft",

  transactions: "/transactions",
  transaction: "/transaction-details",

  reward: "/reward",

  locked: "/locked",

  notfound: "/404",
  createNftManual: "/create-nft-manual",
};
